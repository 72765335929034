import React, { useState } from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import BlogBink, { BlogBinkPosts } from '@ubo/blog-bink'

// Layout
import Grid from 'components/flex/Posts/Layout/Grid'

// Interface
import { PostsProps } from 'components/flex/Posts/PostsShell'

const Highlighted: React.FC<PostsProps> = ({ fields }) => {
  const {
    allWpPost,
    // eslint-disable-next-line
  } = useStaticQuery<GatsbyTypes.highlightedQueryQuery>(graphql`
    query highlightedQuery {
      allWpPost(sort: { order: DESC, fields: date }) {
        edges {
          node {
            ...generalPostFragment
          }
        }
      }
    }
  `)

  const posts: unknown = allWpPost.edges
  const postIds = fields.posts?.map((post) => post?.databaseId)

  return (
    <section className="mb-lg-5">
      <BlogBink
        showIds={postIds as Array<number>}
        posts={posts as BlogBinkPosts}
        id="highlighted-posts"
        limit={Infinity}
      >
        <div className="py-5">
          <Grid fields={fields} slider />
        </div>
      </BlogBink>
    </section>
  )
}

export default Highlighted
